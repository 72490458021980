import React, { FC } from 'react';
import { css, cx } from 'emotion';
import { config } from '@grafana/runtime';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

export const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  const maxSize = css`
    max-width: 150px;
  `;

  if (config.whiteLabel.loginLogo) {
    return (
      <>
        <img className={className} src={config.whiteLabel.loginLogo} alt={AppTitle} />
      </>
    );
  }

  return (
    <>
      <img className={cx(className, maxSize)} src="public/img/kensobi_icon.svg" alt="KensoBI" />
      <div className="logo-wordmark" />
    </>
  );
};

export const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  let background = css`
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url(public/img/lenny-kuhne.jpg) no-repeat center center fixed;
    background-size: cover;
  `;

  if (config.whiteLabel.loginBackground) {
    background = css`
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
        url(${config.whiteLabel.loginBackground}) no-repeat center center fixed;
      background-size: cover;
    `;
  }

  return <div className={cx(background, className)}>{children}</div>;
};

export const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  if (config.whiteLabel.menuLogo) {
    return <img className={className} src={config.whiteLabel.menuLogo} alt={AppTitle} />;
  }

  return <img className={className} src="public/img/kensobi_icon.svg" alt="KensoBI" />;
};

export const AppTitle = config.whiteLabel.appTitle ? config.whiteLabel.appTitle : 'KensoBI';

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static AppTitle = AppTitle;
}
