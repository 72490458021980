import React, { FC } from 'react';
import SideMenuDropDown from './SideMenuDropDown';
import { contextSrv } from 'app/core/services/context_srv';

export interface Props {
  link: any;
}

const TopSectionItem: FC<Props> = props => {
  const { link } = props;
  let style = {};
  const isSignedIn = contextSrv.isSignedIn;

  if (link.id === 'plugin-page-kensobi-file-manager-app') {
    style = { borderRadius: 0 };
  }

  if (!isSignedIn) {
    return null;
  }

  return (
    <div className="sidemenu-item dropdown">
      <a className="sidemenu-link" href={link.url} target={link.target}>
        <span className="icon-circle sidemenu-icon">
          <i className={link.icon} />
          {link.img && <img src={link.img} style={style} />}
        </span>
      </a>
      <SideMenuDropDown link={link} />
    </div>
  );
};

export default TopSectionItem;
