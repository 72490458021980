import _ from 'lodash';
import { colors } from '@grafana/ui';
import { getColorFromHexRgbOrName, TimeRange, FieldType, Field, DataFrame, getTimeField } from '@grafana/data';
import TimeSeries from 'app/core/time_series2';
import config from 'app/core/config';

type Options = {
  dataList: DataFrame[];
  range?: TimeRange;
  xFieldName?: string;
  yFieldName?: string;
};

export const getField = (series: DataFrame, fieldName: string): { field?: Field; fieldIdex?: number } => {
  for (let i = 0; i < series.fields.length; i++) {
    if (series.fields[i].name === fieldName) {
      return {
        field: series.fields[i],
        fieldIdex: i,
      };
    }
  }
  return {};
};

export class DataProcessor {
  constructor(private panel: any) {}

  getSeriesDataList(options: Options): TimeSeries[] {
    const list: TimeSeries[] = [];
    const { dataList, range, xFieldName, yFieldName } = options;

    if (!dataList || !dataList.length || !xFieldName || !yFieldName) {
      return list;
    }

    for (let i = 0; i < dataList.length; i++) {
      const series = dataList[i];
      const xField = getField(series, xFieldName);
      const yField = getField(series, yFieldName);
      const metric = getField(series, 'metric');

      if (!xField.field || !yField.field || yField.field.type !== FieldType.number) {
        const myl = this.getSeriesList({ dataList: [dataList[i]], range: range });
        list.concat(myl);
        continue;
      }

      const seriesName = series.name ? series.name : series.refId;

      const datapoints = [];
      for (let r = 0; r < series.length; r++) {
        datapoints.push([yField.field.values.get(r), xField.field.values.get(r)]);
      }

      let name = yField.field.config && yField.field.config.title ? yField.field.config.title : yField.field.name;

      if (seriesName && dataList.length > 0 && name !== seriesName) {
        name = seriesName + ' ' + name;
      }

      if (metric.field) {
        name = metric.field.values.get(0);
      }

      list.push(this.toDataSeries(yField.field, name, i, yField.fieldIdex, datapoints, list.length, range));
    }

    return list;
  }

  getSeriesList(options: Options): TimeSeries[] {
    const list: TimeSeries[] = [];
    const { dataList, range } = options;

    if (!dataList || !dataList.length) {
      return list;
    }

    for (let i = 0; i < dataList.length; i++) {
      const series = dataList[i];
      const { timeField } = getTimeField(series);
      if (!timeField) {
        continue;
      }

      const seriesName = series.name ? series.name : series.refId;
      for (let j = 0; j < series.fields.length; j++) {
        const field = series.fields[j];
        if (field.type !== FieldType.number) {
          continue;
        }

        let name = field.config && field.config.title ? field.config.title : field.name;

        if (seriesName && dataList.length > 0 && name !== seriesName) {
          name = seriesName + ' ' + name;
        }

        const datapoints = [];
        for (let r = 0; r < series.length; r++) {
          datapoints.push([field.values.get(r), timeField.values.get(r)]);
        }
        list.push(this.toTimeSeries(field, name, i, j, datapoints, list.length, range));
      }
    }

    // Merge all the rows if we want to show a histogram
    if (this.panel.xaxis.mode === 'histogram' && !this.panel.stack && list.length > 1) {
      const first = list[0];
      first.alias = first.aliasEscaped = 'Count';
      for (let i = 1; i < list.length; i++) {
        first.datapoints = first.datapoints.concat(list[i].datapoints);
      }
      return [first];
    }

    return list;
  }

  private toTimeSeries(
    field: Field,
    alias: string,
    dataFrameIndex: number,
    fieldIndex: number,
    datapoints: any[][],
    index: number,
    range?: TimeRange
  ) {
    const colorIndex = index % colors.length;
    const color = this.panel.aliasColors[alias] || colors[colorIndex];

    const series = new TimeSeries({
      datapoints: datapoints || [],
      alias: alias,
      color: getColorFromHexRgbOrName(color, config.theme.type),
      unit: field.config ? field.config.unit : undefined,
      dataFrameIndex,
      fieldIndex,
    });

    if (datapoints && datapoints.length > 0 && range) {
      const last = datapoints[datapoints.length - 1][1];
      const from = range.from;

      if (last - from.valueOf() < -10000) {
        series.isOutsideRange = true;
      }
    }
    return series;
  }

  private toDataSeries(
    field: Field,
    alias: string,
    dataFrameIndex: number,
    fieldIndex: number,
    datapoints: any[][],
    index: number,
    range?: TimeRange
  ) {
    const colorIndex = index % colors.length;
    const color = this.panel.aliasColors[alias] || colors[colorIndex];

    const series = new TimeSeries({
      datapoints: datapoints || [],
      alias: alias,
      color: getColorFromHexRgbOrName(color, config.theme.type),
      unit: field.config ? field.config.unit : undefined,
      dataFrameIndex,
      fieldIndex,
    });
    return series;
  }

  setPanelDefaultsForNewXAxisMode() {
    switch (this.panel.xaxis.mode) {
      case 'time': {
        this.panel.bars = false;
        this.panel.lines = true;
        this.panel.points = false;
        this.panel.legend.show = true;
        this.panel.tooltip.shared = true;
        this.panel.xaxis.values = [];
        break;
      }
      case 'series': {
        this.panel.bars = true;
        this.panel.lines = false;
        this.panel.points = false;
        this.panel.stack = false;
        this.panel.legend.show = false;
        this.panel.tooltip.shared = false;
        this.panel.xaxis.values = ['total'];
        break;
      }
      case 'histogram': {
        this.panel.bars = true;
        this.panel.lines = false;
        this.panel.points = false;
        this.panel.stack = false;
        this.panel.legend.show = false;
        this.panel.tooltip.shared = false;
        break;
      }
      case 'data': {
        this.panel.bars = true;
        this.panel.lines = true;
        this.panel.points = false;
        this.panel.stack = false;
        this.panel.legend.show = false;
        this.panel.tooltip.shared = false;
        this.panel.xaxis.values = [];
        break;
      }
    }
  }

  validateXAxisSeriesValue() {
    switch (this.panel.xaxis.mode) {
      case 'series': {
        if (this.panel.xaxis.values.length === 0) {
          this.panel.xaxis.values = ['total'];
          return;
        }

        const validOptions = this.getXAxisValueOptions({});
        const found: any = _.find(validOptions, { value: this.panel.xaxis.values[0] });
        if (!found) {
          this.panel.xaxis.values = ['total'];
        }
        return;
      }
    }
  }

  getXAxisValueOptions(options: any) {
    switch (this.panel.xaxis.mode) {
      case 'series': {
        return [
          { text: 'Avg', value: 'avg' },
          { text: 'Min', value: 'min' },
          { text: 'Max', value: 'max' },
          { text: 'Total', value: 'total' },
          { text: 'Count', value: 'count' },
        ];
      }
    }

    return [];
  }

  pluckDeep(obj: any, property: string) {
    const propertyParts = property.split('.');
    let value = obj;
    for (let i = 0; i < propertyParts.length; ++i) {
      if (value[propertyParts[i]]) {
        value = value[propertyParts[i]];
      } else {
        return undefined;
      }
    }
    return value;
  }
}
